
import { BlockPeriod, IGenericError, UnsualPeriodResponse } from '@libs/entity-lib';
import { createEntityAdapter, EntityAdapter, EntityState, Update } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as fromCustomer from './unusual-period.actions';

export interface State extends EntityState<UnsualPeriodResponse> {
    loading: boolean;
    error: IGenericError | null;
}

const adapter: EntityAdapter<UnsualPeriodResponse> = createEntityAdapter<UnsualPeriodResponse>({
  selectId: (unsualPeriod: UnsualPeriodResponse) => unsualPeriod.id
});

export const initialState: State = adapter.getInitialState({
  loading: false,
  error: null,
});


export const reducer = createReducer(
  initialState,
  on(fromCustomer.fetchUnusualPeriod, (state) =>  ({ ...state, loading: true, error: null })),
  on(fromCustomer.fetchUnusualPeriodSuccessfuly, (state, { unsualPeriodList }) => {
    return adapter.addMany(unsualPeriodList, {
      ...state,
      loading: false,
      error: null
    })
  }),
  on(fromCustomer.fetchUnusualPeriodFailed, (state, { error }) =>  adapter.setAll([], { ...state, loading: false, error })),

  // Add
  on(fromCustomer.addUnusualPeriod, (state) =>  ({ ...state, loading: true, error: null })),
  on(fromCustomer.addUnusualPeriodSuccessfuly, (state) => ({
    ...state,
    loading: false,
    error: null
  })),
  on(fromCustomer.addUnusualPeriodFailed, (state, { error }) =>  adapter.setAll([], { ...state, loading: false, error })),

// Update
on(fromCustomer.updateUnusualPeriod, (state) =>  ({ ...state, loading: true, error: null })),
on(fromCustomer.updateUnusualPeriodSuccessfuly, (state) => ({
  ...state,
  loading: false,
  error: null
})),
on(fromCustomer.updateUnusualPeriodFailed, (state, { error }) =>  adapter.setAll([], { ...state, loading: false, error })),


  // Remove
  on(fromCustomer.removeUnusualPeriod, (state) =>  ({ ...state, loading: true, error: null })),
  on(fromCustomer.removeUnusualPeriodSuccessfuly, (state, { id }) => {
    return adapter.removeOne(id, {
      ...state,
      loading: false,
      error: null
    })
  }),
  on(fromCustomer.removeUnusualPeriodFailed, (state, { error }) =>  adapter.setAll([], { ...state, loading: false, error }))
);

export const { selectAll } = adapter.getSelectors();